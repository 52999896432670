<template>
  <v-card :color="ant" outlined class="px-4 pb-3">
    <v-card-title class="mt-3">
      Price <strong class="ml-3"> ${{ amount }}</strong>
    </v-card-title>
    <v-card-text class="">
      <p>My share: {{ myRealShare }} (80%)</p>
      <p>{{ !order.bids ? 'no open bids' :`${order.bids.length} bid(s)`}}</p>
    </v-card-text>
    <v-card-actions class="mt-n5 pb-5">

      <!-- Place order dialogue -->
      <v-dialog
        v-model="dialog1"
        width="450"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
          color="primary" 
          class="px-3" 
          :loading="loading" 
          v-bind="attrs"
          v-on="on"
          v-show="!editBid" 
          >
            <v-icon>mdi-lock-outline</v-icon> PLACE MY BID
          </v-btn>
        </template>

        <v-card light>
          <v-form ref="editForm" @submit.prevent="placeBid" v-model="valid">
            <v-card-title class="headline grey lighten-5">
              Place my bid
              <v-spacer></v-spacer>
              <v-icon @click="dialog1 = false">mdi-close</v-icon>
            </v-card-title> 
            <v-divider></v-divider>

            <v-card-text class="mt-5">
              <div class="d-flex">
                <label for=""> My bid(USD)</label> <v-spacer></v-spacer> <span> min ${{order.amount}}</span>
              </div>

              <v-text-field :rules="amountRules()" outlined solo background-color="grey lighten-3" class="rounded-md" v-model="amount"></v-text-field>

              <v-row class="pl-0 mt-n5">
                <v-col cols="5">
                  <!-- <div class="d-flex">
                    <span>CPP:</span> <v-spacer></v-spacer> <span> $13899.54</span>
                  </div> -->
                  <div class="d-flex">
                    <span >My Share:</span> <v-spacer></v-spacer> <span> $ {{ myShare }}</span>
                  </div>
                </v-col>
                <v-col></v-col>
              </v-row>
              

            </v-card-text>

            <v-divider class=" mt-5"></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              color="grey lighten-2"
              class="mr-3"
              @click="dialog1 = false"
              >
              Cancel
              </v-btn>
              <v-btn
                type="submit"
                color="success dark"
                :loading="loading"
                :disabled="!valid"
              >
                Place My Bid
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    <!-- Place order dialogue -->

    <!-- edit order dialouge  -->
      <v-dialog
        v-model="dialog"
        width="450"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
          color="primary" 
          class="px-3" 
          :loading="loading" 
          v-bind="attrs"
          v-on="on"
          v-show="editBid"
          >
            <v-icon>mdi-pencil</v-icon> EDIT BID
          </v-btn>
        </template>

        <v-card light>
          <v-form ref="editForm" @submit.prevent="updateBid" v-model="valid">
            <v-card-title class="headline grey lighten-5">
              Edit my bid
              <v-spacer></v-spacer>
              <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-card-title> 
            <v-divider></v-divider>

            <v-card-text class="mt-5">
              <div class="d-flex">
                <label for=""> My bid(USD)</label> <v-spacer></v-spacer> <span> min ${{order.amount}}</span>
              </div>

              <v-text-field :rules="amountRules()" outlined solo background-color="grey lighten-3" class="rounded-md" v-model="editAmount"></v-text-field>

              <v-row class="pl-0 mt-n5">
                <v-col cols="5">
                  <!-- <div class="d-flex">
                    <span>CPP:</span> <v-spacer></v-spacer> <span> $13899.54</span>
                  </div> -->
                  <div class="d-flex">
                    <span >My Share:</span> <v-spacer></v-spacer> <span> $ {{ myEditShare }}</span>
                  </div>
                </v-col>
                <v-col></v-col>
              </v-row>
            </v-card-text>
            <v-divider class=" mt-5"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              color="grey lighten-2"
              class="mr-3"
              @click="dialog = false"
              >
              Cancel
              </v-btn>
              <v-btn
                color="success dark"
                type="submit"
                :loading="loading"
                :disabled="!valid"
              >
                Edit My Bid
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    <!-- edit order dialouge  -->


      <v-spacer></v-spacer>
      <v-btn outlined class="px-9 py-5" text> HIDE </v-btn>
    </v-card-actions>
   


    
  </v-card>
</template>

<script>
import { round } from 'mathjs'
import { mapGetters } from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin";

export default {
  name: "placebid",
  props: ['order'],
  mixins: [colorMxn,validationMxn],
  data() {
    return {
      loading: false,
      editBid: false,
      dialog1: false,
      dialog: false,
      amount: !this.order.amount ? 0.00 : this.order.amount,
      editAmount: !this.order.amount ? 0.00 : this.order.amount,
      myEditShare: 0.0,
      myShare: 0.0,
      valid: false,
    };
  },
  computed: {
    ...mapGetters(['getSession']),
    myRealShare() {
      const division = (this.amount/ 100) * 80;
      return round(division);
    }
  },
  watch: {
    amount(val) {
      const division = (val/ 100) * 80;
      this.myShare = round(division);
    },
    editAmount(val) {
      const division = (val/ 100) * 80;
      this.myEditShare = round(division);
    },
  },
  mounted() {
    this.bidstatus();
  },
  methods: {
    async placeBid() {
      this.loading = true;
      const payload = {
        order_id: this.$route.params.id,
        amount: this.amount,
        writer_id: this.getSession.writer.id,
      };
      const fullPayload = {
        params: payload,
        endpoint: `/bid/store-bid`,
      }
      const response = await this.performPostActions(fullPayload);
      this.editBid = response.status === 200;
      this.amount = response.data.amount;
      this.loading = false;
      this.dialog1 =false;
      this.$emit('activateChat', true);
    },
    async updateBid() {
      this.loading = true;
      const payload = {
        order_id: this.$route.params.id,
        amount: this.editAmount,
        writer_id: this.getSession.writer.id,
      };
      const fullPayload = {
        params: payload,
        endpoint: `/bid/update-bid/${this.$route.params.id}`,
      }
      const response = await this.performUpdateActions(fullPayload);
      this.amount = response.data.amount;
      this.editAmount = response.data.amount;
      this.loading = false;
      this.dialog = false;
    },
    async bidstatus() {
      const payload = {
        order_id: this.$route.params.id,
        writer_id: this.getSession.writer.id,
      };

      const fullPayload = {
        params: payload,
        endpoint: '/bid/bid-exists',
      };
      const response = await this.performGetActions(fullPayload);
      this.amount = response.data.amount;
       this.editAmount = response.data.amount;
      this.editBid = response.status === "success";
    },
    amountRules() {
      const minimumAmount = this.order.amount;
      return [
        v => !!v || "Amount is required",
        v => /^[+-]?\d+(\.\d+)?$/.test(v) || "Only digits are required",
        v => v > 0 || "Amount: Should be greator than 0.01",
        v => v >= parseInt(minimumAmount) || `Amount: Should be greator than ${minimumAmount}`
      ]
    },
  }
};
</script>
